import { MenuItem } from './menu.model';

/**
 * Add roles attribute to limit access
 * the roles in roles attribute are allowed to access menu item
 * when no roles attribute is present, all roles are allowed to access menu item
 */
export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    link: '/pages/dashboard',
    icon: 'assets/images/icons/dashboard.png',
    roles: [1, 2, 3, 4, 6, 7]
  },
  {
    id: 2,
    label: 'Leads',
    icon: 'assets/images/icons/third-party.png',
    isUiElement: true,
    roles: [1, 2, 3, 4, 6, 7],
    subItems: [
      {
        id: 21,
        label: 'Leads Customers',
        link: '/third-party/leads',
        parentId: 2,
        roles: [1, 3, 4, 7]
      },
      {
        id: 22,
        label: 'Leads Suppliers',
        link: '/third-party/lead-suppliers',
        parentId: 2,
        roles: [1, 2, 4,6]
      },
    ]
  },
  {
    id: 3,
    label: 'Clients',
    icon: 'assets/images/icons/third-party.png',
    isUiElement: true,
    roles: [1, 2, 3, 4, 6, 7],
    subItems: [
      {
        id: 31,
        label: 'Costumers',
        link: '/third-party/customers',
        parentId: 3,
        roles: [1, 3, 4, 7]
      },
      {
        id: 32,
        label: 'Suppliers',
        link: '/third-party/suppliers',
        parentId: 3,
        roles: [1, 2, 4, 6]
      },
    ]
  },
  {
    id: 4,
    label: 'Team BBG',
    icon: 'assets/images/icons/sales.png',
    roles: [1, 5, 6, 7],
    subItems: [
      {
        id: 41,
        label: 'Prchase',
        link: '/sales/purchase',
        parentId: 4
      },
      {
        id: 42,
        label: 'Export',
        link: '/sales/export',
        parentId: 4
      },
      {
        id: 43,
        label: 'Marketing',
        link: '/marketing/list',
        parentId: 4,
        icon: 'assets/images/icons/sales.png',
        roles: [1]
      },
      {
        id: 44,
        label: 'Managers',
        link: '/managers/list',
        parentId: 4,
        roles: [1]
      }
    ]
  },
  {
    id: 5,
    label: 'Catalog',
    icon: 'assets/images/icons/catalog.png',
    roles: [1, 2, 3, 4, 6, 7],
    subItems: [
      {
        id: 51,
        label: 'Catalog',
        link: '/catalogue/list',
        parentId: 5
      },
    ]
  },
  {
    id: 6,
    label: 'Mailing',
    icon: 'assets/images/icons/mailing.png',
    roles: [1, 4],
    subItems: [
      {
        id: 61,
        label: 'Templates',
        link: '/mails/templates',
        parentId: 6
      },
      {
        id: 62,
        label: 'Email',
        link: '/mails',
        parentId: 6
      },
      {
        id: 63,
        label: 'Whatsapp',
        link: '/whatsapp/templates',
        parentId: 6
      },
    ]
  },

  {
    id: 7,
    label: 'Orders',
    link: '/orders/list-orders',
    icon: 'assets/images/icons/third-party.png',
    roles: [2, 3, 5, 6, 7]
  },
  {
    id: 10,
    label: 'Orders',
    icon: 'assets/images/icons/third-party.png',
    roles: [1],
    subItems: [
      {
        id: 101,
        label: 'List',
        link: '/orders/list-orders',
        parentId: 10
      },
      {
        id: 102,
        label: 'Document Types',
        link: '/orders/document-types',
        parentId: 10
      }
    ]
  },
  {
    id: 8,
    label: 'Tags',
    link: '/tags/list-tags',
    icon: 'assets/images/icons/mailing.png',
    roles: [1, 2, 3, 4, 6, 7]
  },
  {
    id: 9,
    label: 'Products',
    link: '/products/product-file-manager',
    icon: 'assets/images/icons/products-icon.png',
    roles: [1, 2, 3, 6, 7]
  },
];

