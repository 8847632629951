import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DomainGuard {
  dns: string = environment.backofficeUrl; 
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const host = window.location.origin;
      if (host !== this.dns) {
        if (state.url.includes('external') || state.url === '/not-found') {
          return true;
        } else {
          this.router.navigate(['/not-found']);
          return false;
        }
      } else {
        return true;
      }
    }
}
