export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  backUrl: "https://dev.api.big-brands.vayetek.com/api",
  filesUrl: 'https://big-brands-crm-dev.s3.eu-west-3.amazonaws.com/',
  countryUrl: "http://ip-api.com/json?fields=query,country",
  backofficeUrl: "https://dev.big-brands.vayetek.com",
  bbgCatalog: "https://dev.big-brands.vayetek.com",
  teamUrl: "https://dev.big-brands.vayetek.com",
  anugaUrl: "https://dev.big-brands.vayetek.com"
};
