import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Admin } from '../models/Admin';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    userSubject = new BehaviorSubject<Admin | null>(null);
    user$ = this.userSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        this.clearAdmin();
        const url = environment.backUrl + '/auth/logout';
        return this.http.get(url);
    }

    loginAdmin(email: string, password: string) {
        const url = environment.backUrl + '/auth/login/admin';
        return this.http.post(url, { email: email, password: password });
    }

    resetPasswordAdmin(email: string) {
      const url = environment.backUrl + '/auth/reset-password';
      return this.http.post(url, { email: email });
    }

    setAdmin(admin: Admin) {
        this.userSubject.next(admin);
    }

    getAdmin() {
        return this.user$;
    }

    clearAdmin() {
        this.userSubject.next(null);
    }
}

