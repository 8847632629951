import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { DomainGuard } from './core/guards/domain.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdminRoleGuard } from './core/guards/admin-role.guard';
const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [DomainGuard],
  },
  {
    path: 'external',
    loadChildren: () => import('./external/external.module').then(m => m.ExternalModule),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  // tslint:disable-next-line: max-line-length
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, DomainGuard],
    children: [
      { path: '', redirectTo: 'orders/list-orders', pathMatch: 'full' }
      ,{
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },
      {
        path: 'third-party',
        loadChildren: () => import('./manage-third-parties/manage-third-parties.module').then(m => m.ManageThirdPartiesModule)
      },
      { 
        path: 'sales',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1, 5, 6, 7] }],
        loadChildren: () => import('./manage-sales/manage-sales.module').then(m => m.ManageSalesModule) 
      },
      { 
        path: 'marketing',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1] }],
        loadChildren: () => import('./manage-marketing/manage-marketing.module').then(m => m.ManageMarketingModule) 
      },
      { 
        path: 'exhibition',
        loadChildren: () => import('./manage-exhibition/manage-exhibition.module').then(m => m.ManageExhibitionModule) 
      },
      { 
        path: 'mails',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1, 4] }],
        loadChildren: () => import('./manage-mail/manage-mail.module').then(m => m.ManageMailModule) 
      },
      {
        path: 'orders',
        loadChildren: () => import('./manage-orders/manage-orders.module').then(m => m.ManageOrdersModule) 
      },
      { 
        path: 'catalogue',
        loadChildren: () => import('./manage-catalogue/manage-catalogue.module').then(m => m.ManageCatalogueModule) 
      },
      { 
        path: 'tags',
        loadChildren: () => import('./manage-tags/manage-tags.module').then(m => m.ManageTagsModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./manage-contacts/manage-contacts.module').then(m => m.ManageContactsModule) 
      },
      {
        path: 'products',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1, 2, 3, 6, 7] }],
        loadChildren: () => import('./manage-products/manage-products.module').then(m => m.ManageProductsModule) 
      },
      {
        path: 'managers',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1] }],
        loadChildren: ()=> import('./manage-managers/manage-managers.module').then(m => m.ManageManagersModule)
      },
      {
        path: 'whatsapp',
        canActivate: [AdminRoleGuard],
        data: [{ requiredRoles: [1, 4] }],
        loadChildren: ()=> import('./manage-whatsapp/manage-whatsapp.module').then(m => m.ManageWhatsappModule)
      }
    ],
  },
  {
    path: '**',
    canActivate:[DomainGuard,AuthGuard],
    component:NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }