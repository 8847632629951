import { Country } from "./Country";
import { Language } from "./language";

export class Admin {
  admin_id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  second_mobile: string;
  country_id: number;
  picture: string;
  role_id: number;
  exhibition_id: number;
  qr_code: string;
  linkedin: string;
  languageIds: number [] = [];
  role?: {
    role_id: number;
    label: string;
  };
  languages: Language[] = [];
  whatsapp: string;
  country: Country = new Country();
  position: string;
  nb_scan: number;
  commercialsIds: number[];
  commercials?: Admin[];
}
