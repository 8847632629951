<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="javascript::void()" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-big-brands.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-big-brands.png" alt="" height="70">
          </span>
        </a>

        <a href="javascript::void()" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-big-brands.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-big-brands.png" alt="" height="70">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>
    <div class="d-flex">
      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" dropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
            <span class="m-1 ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="m-1 -ms-1" height="12"> <span class="align-middle">{{item.text}}</span>
          </a>
        </div>  
      </div>
      <div class="d-inline-block me-1" *ngIf="role != 1"  (click)="showQrCode()">
        <button type="button" class="btn header-item noti-icon" 
        data-toggle="modal" data-target="#centerDataModal">
          <i class="bx bx-customize"></i>
        </button>
      </div>

      <div class="dropdown me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" [routerLink]="['/third-party/scan-business-card']">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" dropdownToggle>
          <img class="rounded-circle header-profile-user d-xl-none d-xxl-none" [src]="'https://ui-avatars.com/api/?name=' + admin.first_name + ' ' + admin.last_name" alt="Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{ admin.first_name }} {{ admin.last_name }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="goToCatalog('showPrice')">
            <svg xmlns="http://www.w3.org/2000/svg" class="mb-1" width="15" height="15" viewBox="0 0 48 48"><g fill="none"><path d="M39 32H13L8 12h36z"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M3 6h3.5L8 12m0 0l5 20h26l5-20z"/><circle cx="13" cy="39" r="3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/><circle cx="39" cy="39" r="3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></g></svg>
            {{ 'Show Price' | translate}}
          </a>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="goToCatalog('guest')">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20"><path fill="currentColor" d="M7.085 4H5.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-1.585A1.5 1.5 0 0 1 11.5 5h-3a1.5 1.5 0 0 1-1.415-1m5.83-1H14.5A1.5 1.5 0 0 1 16 4.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 4 16.5v-12A1.5 1.5 0 0 1 5.5 3h1.585A1.5 1.5 0 0 1 8.5 2h3a1.5 1.5 0 0 1 1.415 1M8 3.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5M9 8a1 1 0 1 1 2 0a1 1 0 0 1-2 0m1-2a2 2 0 1 0 0 4a2 2 0 0 0 0-4m-2.474 6.196a.15.15 0 0 1 .048-.125A.249.249 0 0 1 7.75 12h4.5a.25.25 0 0 1 .176.071a.15.15 0 0 1 .047.125c-.048.455-.179.886-.498 1.206c-.312.313-.882.598-1.975.598s-1.663-.285-1.975-.598c-.32-.32-.45-.751-.499-1.206M7.75 11c-.664 0-1.298.55-1.218 1.302c.06.56.235 1.254.784 1.806c.557.558 1.418.892 2.684.892c1.266 0 2.127-.334 2.684-.892c.55-.552.725-1.247.784-1.806c.08-.752-.553-1.302-1.218-1.302z"/></svg>
            {{ 'Guest User' | translate}}
          </a>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'LOGOUT' | translate}}
          </a>
        </div>
      </div>

    </div>
  </div>
</header>
<div class="topnav">
  <div [ngClass]="{'container': isLangEn, 'ps-2': !isLangEn}">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav d-flex flex-wrap">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">

              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <img src="{{item.icon}}" alt="menu-icon" height="17" class="pb-1">
                {{ item.label | translate }}
                <div class="arrow-down" *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none d-none d-lg-block" 
                href="javascript: void(0);" id="topnav-components" role="button">
                <img src="{{item.icon}}" alt="menu-icon" height="16">
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>
              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none d-lg-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <img src="{{item.icon}}" alt="menu-icon" height="16">
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}"
                aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    {{subitem.label | translate }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a>
                      </div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="item.subItems.length == 11 && !hasItems(subitem)">
                    <!-- <div class="dropdown" *ngIf="hasItems(subitem)"> -->
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <!-- </div> -->
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link" routerLinkActive="active">{{
                          subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">

                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link" routerLinkActive="active">{{ subSubSubitem.label |
                                translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
