import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENU',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboards',
        icon: 'bx bx-home-circle',
        subItems: [
            {
                id: 3,
                label: 'Default',
                link: '/',
                parentId: 2
            },
        ]
    },
    {
        id: 8,
        isLayout: true
    },
    {
        id: 9,
        label: 'APPS',
        isTitle: true
    },
    {
        id: 10,
        label: 'Commercials',
        icon: 'bx bx-briefcase-alt',
        link: '/',
        subItems: [
            {
                id: 11,
                label: 'CommercialsList',
                link: '/',
                parentId: 10
            },
        ]
    },
    {
        id: 13,
        label: 'Tiers',
        icon: 'fas fa-users',
        subItems: [
            {
                id: 14,
                label: 'TiersList',
                link: '/',
                parentId: 13
            },
        ]
    },
    {
        id: 15,
        label: 'Clients',
        icon: 'fas fa-user-friends',
        subItems: [
            {
                id: 16,
                label: 'ClientsList',
                link: '/',
                parentId: 15
            },
        ]
    },
    {
        id: 17,
        label: 'Suppliers',
        icon: 'bx bxs-user-detail',
        subItems: [
            {
                id: 18,
                label: 'SuppliersList',
                link: '/',
                parentId: 17
            },
        ]
    }
];

