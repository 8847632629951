import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../services/auth.service";
import { Admin } from "../models/Admin";
import Swal from "sweetalert2";

@Injectable({ providedIn: "root" })
export class AdminRoleGuard {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let requiredRoles = route.data[0].requiredRoles as Array<number>;
    let currentUrl = this.router.url;
    let admin: Admin;
    this.authService.getAdmin().subscribe((data: any) => {
      admin = data;
    });
    if (admin && requiredRoles.includes(admin.role_id)) {
      return true;
    } else {
      Swal.fire({
        icon: "error",
        title: "Denied Access",
        text: "You do not have permission to access this page",
        confirmButtonText: "Continue to home page",
      }).then(() => {
        this.router.navigate(["/"]);
      });
      return false;
    }
  }
}
